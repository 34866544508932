import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Loader from '../../../components/Loader';
import CattleDetailsNote from '../../../components/CattleDetailsNote';

import css from './_styles.module.scss';

import Client from '../../../apis/Client';

function Details(props) {
  const {
    cattleId,
    data,
    isLoading,
    locationId,
    showHeader,
    mode = 'light'
  } = props;

  const navigate = useNavigate();

  function openLiveView() {
    navigate(`/producers/herd-sense?locationId=${locationId}&deviceId=${data?.devices[0].id}&cattleId=${cattleId}`);
  }

  if (!data) {
    return null;
  }

  const header = (
    <div className={css.header}>
      <h2>ID: {data?.name || data?.id}</h2>
    </div>
  );

  const imgBlock = (
    <div className={css.imgBlock} style={{ marginTop: showHeader ? 0 : 20 }}>
      {/*<img src={data?.mug_url} alt='' />*/}
      <button onClick={openLiveView} className={`btn btn-outline-primary`}>Live View Animal</button>
    </div>
  );

  const seenBlock = (
    <div className={css.seenBlock}>
      <div className={css.item}>
        <h4>First Seen</h4>
        <span>{moment.utc(data?.created_at).local().format('M/D/YY hh:mm A')}</span>
      </div>

      <div className={css.item}>
        <h4>Last Seen</h4>
        <span>{moment.utc(data?.updated_at).local().format('M/D/YY hh:mm A')}</span>
      </div>
    </div>
  );

  const gaitGraph = (
    <div className={css.graphBlock}>
      <div className={css.infoRow}>
        <div className={css.label}>Gait Graph</div>
        <div className={css.timespan}>All Time</div>
      </div>
      <div className={css.graph} style={{ backgroundColor: mode === 'dark' ? '#f6f6f6' : '#fff' }} />
    </div>
  );

  const headHangingGraph = (
    <div className={css.graphBlock}>
      <div className={css.infoRow}>
        <div className={css.label}>Head Hanging</div>
        <div className={css.timespan}>All Time</div>
      </div>
      <div className={css.graph} style={{ backgroundColor: mode === 'dark' ? '#f6f6f6' : '#fff' }} />
    </div>
  );

  const historyEls = (data?.health_notes || [])
    .sort((a, b) => a.created_at < b.created_at ? 1 : -1)
    .map((note) => (
      <CattleDetailsNote key={note.id} note={note} />
  ));

  const animalHistory = (
    <div className={css.animalHistory}>
      <h3>Animal History</h3>
      <div className={css.roll}>
        {historyEls}
      </div>
    </div>
  );

  return (
    <div className={css.infoPanel}>
      <div className={css.sidebarContainer}>
        <Loader loading={isLoading}>
          <div className={css.content}>
            {/* {header} */}

            {imgBlock}
            {seenBlock}
            {gaitGraph}
            {headHangingGraph}
            {animalHistory}
          </div>
        </Loader>
      </div>
    </div>
  );
}

export default Details;

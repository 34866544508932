import React from 'react';
import { Formik, Form } from 'formik';
import {Modal} from 'react-bootstrap';
import useMutation from '../../hooks/useMutation';

import CustomInput from '../../components/forms/CustomInput';
import FormModal from '../../components/FormModal';

import {required} from '../../validation/form';

import Client from '../../apis/Client';

import css from './styles.module.scss';

const validate = (values) => {
  const errors = {};
 
  required(errors, values, 'description');

  return errors;
};

function RoundNoteModal(props) {
  const {
    showModal,
    setShowModal,
    refetch,
    areaId,
    locationId
  } = props;

  const initialValues = {
    description: '',
    areaId
  };

  const { mutate: createRoundNote, isLoading, isError, error } = useMutation({
    mutationKey: 'post_round_note',
    mutationFn: (data) => {
      const url = `/api/v1/producers/locations/${locationId}/round-notes`;
      return Client.post(url, data);
    },
    onSuccess: (data) => {
      refetch();
      setShowModal(false);
    },
  });

  const onSubmit = (values) => {
    createRoundNote(values);
  };

  return (
    <FormModal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Add Note"
      submitBtnLabel="Save"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      error={error}
    >
      {(formik) => (
        <CustomInput
          label="Description"
          name="description"
          formik={formik}
          textarea
          placeholder="Add description here"
        />
      )}
    </FormModal>
  );
}

export default RoundNoteModal;

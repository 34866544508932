import React, {
  useContext,
  forwardRef,
  useState
} from 'react';

import useMutation from '../../../hooks/useMutation';

import moment from 'moment';

import AuthContext from '../../../context/AuthContext';

import ChatScroll from '../../../components/ChatScroll';

import ChatBox from './ChatBox';

import Client from '../../../apis/Client';

import css from './_styles.module.scss';

const ThuImage = require('../../../images/laurenBouchard.png');
const EthanImage = require('../../../images/brendanKurt.png');

const FETCH_LIMIT = 15;

const ChatThreads = forwardRef((props, ref) => {
  const {
    readChat,
    locationId,
    scrollRef,
    head,
    cattleId,
    portal
  } = props;

  const { userData } = useContext(AuthContext);

  const [dataEnd, setDataEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const baseUrl = portal === 'producer'
    ? `/api/v1/producers/locations/${locationId}/chat-notes?limit=${FETCH_LIMIT}`
    : `/api/v1/vets/locations/${locationId}/chat-notes?limit=${FETCH_LIMIT}`;

  const { mutate: getChatNotes } = useMutation({
    mutationKey: 'get_cattle_convo',
    mutationFn: (id) => Client.get(`${baseUrl}&cattle_id=${id}`),
    onSuccess: (data) => {
      scrollRef?.current?.loadInitial(data);
      setDataEnd(data.length < FETCH_LIMIT);
      setIsLoading(false);
      readChat();
    }
  });

  const { mutate: moreChatNotes, isLoading: isLoadingMore } = useMutation({
    mutationKey: 'get_cattle_convo_more',
    mutationFn: ({ id, date }) => Client.get(`${baseUrl}&cattle_id=${id}&to=${date}`),
    onSuccess: (data) => {
      scrollRef.current.loadData(data);
      setDataEnd(data.length < FETCH_LIMIT);
      readChat();
    }
  });

  function handleOnScrollTop(id, date) {
    if (dataEnd || isLoading || isLoadingMore) return;
    moreChatNotes({ id, date });
  }

  const renderMessageEl = (message) => {
    const avatar = message.created_user.first_name === 'Ethan' ? EthanImage : ThuImage;

    const name = `${message.created_user.first_name} ${message.created_user.last_name}`;

    return (
      <div
        key={message.id}
        className={`${css.message} ${message.created_by === userData.id ? css.right : ''}`}
      >
        {/*<img src={avatar} alt={message.name} />*/}
        <div className={css.messageGroup}>
          <div className={css.name}>{name}</div>
          <div className={css.blurb}>
            {message.note}
            {message.created_by === userData.id
              ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
                  <path d="M15.8252 10.2696C11.5462 7.57078 10.4305 2.61097 11.1197 0.917019C6.34412 1.60868 -2.52337 6.52878 1.27258 8.45987C4.76684 10.2375 12.3439 10.6517 15.682 10.6437C15.8913 10.6432 15.9906 10.3739 15.8252 10.2696Z" fill="#3099FD"/>
                </svg>
              )
              : (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
                  <path d="M0.174801 10.2696C4.45385 7.57078 5.56952 2.61097 4.88025 0.917019C9.65588 1.60868 18.5234 6.52878 14.7274 8.45987C11.2332 10.2375 3.65607 10.6517 0.318038 10.6437C0.10866 10.6432 0.00940692 10.3739 0.174801 10.2696Z" fill="#EFEFEF"/>
                </svg>
              )
            }
          </div>
          <div className={css.time}>{moment.utc(message.created_at).local().fromNow()}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={css.chatBlock}>
      <div className={css.chatHeader}>
        <div className={css.idActions}><strong>ID:</strong>{head?.name}</div>
      </div>

      <ChatScroll
        renderMessage={renderMessageEl}
        onScrollTop={handleOnScrollTop}
        ref={scrollRef}
        onLoad={getChatNotes}
        loadKey={cattleId}
        className={css.chatDisplay}
      />

      <ChatBox locationId={locationId} cattleId={head?.id} portal={portal} />
    </div>
  );
});

export default ChatThreads;

import React, { useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import useQuery from '../../hooks/useQuery';

import Client from '../../apis/Client';

import NoteCard from './NoteCard';
import LocationHeader from './Location-header';

import ButtonGroupFilter from '../../components/ButtonGroupFilter/ButtonGroupFilter';
import CustomTable from '../../components/CustomTable';

import css from './styles.module.scss';

const columnsInfo = [
  {
    name: 'name',
    label: 'ID'
  },
  {
    name: 'monitoring_area',
    label: 'Pen',
    valFn: (head) => head.monitoring_area.name
  },
  {
    name: 'priority',
    label: 'Priority',
    valFn: (head) => head.health_note.priority
  },
  {
    name: 'description',
    label: 'Notes',
    valFn: (head) => head.health_note.description
  },
  {
    name: 'created_by',
    label: 'Created By',
    valFn: (head) => {
      const createdBy = head.health_note.created_user;
      return `${createdBy.first_name} ${createdBy.last_name}`;
    }
  },
  {
    name: 'created_at',
    label: 'Created At',
    valFn: (head) => moment.utc(head.health_note.created_at).local().format('DD/MM/YYYY HH:mm')
  },
];

function VetCheck(props) {
  const { locationId } = props;

  const [activeFilter, setActiveFilter] = useState('overview');
  const [activeGridTable, setActiveGridTable] = useState('grid');

  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ['vet_location', locationId],
    queryFn: () => Client.get(`/api/v1/vets/locations/${locationId}`)
  });

  const { data: cattleList, isLoading: isCattleLoading } = useQuery({
    queryKey: ['get_cattle_with_notes', locationId],
    queryFn: () => {
      let url = `/api/v1/vets/locations/${locationId}/cattle`;
      return Client.get(url)
    },
    enabled: !!data,
  });

  if (isLoading) {
    return null; // loading
  }

  if (!data) {
    return null; // error
  }

  const filteredCattle = cattleList?.filter(it => {
    if (activeFilter === 'overview') {
      return true;
    }

    if (activeFilter === 'routine') {
      return it.health_note.priority === 'check';
    }

    if (activeFilter === 'urgent') {
      return it.health_note.priority === 'urgent';
    }
  })

  function gotoVetChat(cattleId) {
    let url = `/vets/chat?locationId=${locationId}`;
    if (cattleId) {
      url += `&cattleId=${cattleId}`;
    }

    navigate(url);
  }

  let cattleEls = <span>No cows available</span>
  if (filteredCattle?.length) {
    if (activeGridTable === 'grid') {
      cattleEls = filteredCattle?.map((head) => (
        <NoteCard
          key={head.id}
          locationId={locationId}
          head={head}
          gotoVetChat={gotoVetChat}
        />
      ));
    } else {
      cattleEls = (
        <CustomTable
            rows={filteredCattle}
            columns={columnsInfo}
            onRowClick={(e, row) => gotoVetChat(row.id)}
        />
      );
    }
  }

  const companyName = data.company.display_name || data.company.name;

  let locationAddress = data.address.address1;
  if (data.address.address2) {
    locationAddress += ` ${data.address.address2}`;
  }

  locationAddress += `, ${data.address.city}, ${data.address.state}, ${data.address.postal_code}`;

  const pocName = `${data.poc_first_name} ${data.poc_last_name}`;

  const arrFilters = ['overview','urgent','routine'];

  function formatPhone(str) {
    var cleaned = ('' + str).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '', match[2], '.', match[3], '.', match[4]].join('');
    }
    return null;
  }

  function changeFilter(el) {
    setActiveFilter(el);
  }

  function changeFilterGridList(el) {
    setActiveGridTable(el);
  }

  return (
    <div className={css.content}>
      <LocationHeader
        data={data}
        filters={arrFilters}
        activeFilter={activeFilter}
        onClick={changeFilter} />

      <div className={css.infobar}>
        <div className={css.group}>
          <div className={css.item}>
            <h3>Client</h3>
            <span>{companyName}</span>
          </div>
          <div className={css.item}>
            <h3>Location</h3>
            <span>{locationAddress}</span>
          </div>
          <div className={css.item}>
            <h3>Point Of Contact</h3>
            <span>{pocName}</span>
          </div>
          <div className={css.item}>
            <h3>Contact Phone</h3>
            <span>{formatPhone(data.poc_phone)}</span>
          </div>
          <div className={css.item}>
            <h3>Contact Email</h3>
            <span>{data.poc_email}</span>
          </div>
        </div>

        <div className={css.group}>
          <div className={css.item}>
            <h3>Head Monitored</h3>
            <span>{data.total_cattle}</span>
          </div>
          <div className={css.item}>
            <h3>Vet Checks</h3>
            <span>{cattleList?.length}</span>
          </div>
          <div className={css.item}>
            <h3>Last Update</h3>
            <span>{moment().format('MM/DD/YY')}</span>
          </div>
        </div>
      </div>

      <div className={css.cattleSection}>
        <div className={css.lengthView}>
          <div className={css.length}>{cattleList?.length} Item{cattleList?.length > 1 ? 's' : ''}</div>
          <ButtonGroupFilter
            showGridTable={true}
            activeGridTable={activeGridTable}
            onClick={changeFilterGridList} />
        </div>

        <div className={`${css.cattleCards} ${activeGridTable === 'list' ? css.list : null}`}>
          {cattleEls}
        </div>
      </div>
    </div>
  );
}

export default VetCheck;

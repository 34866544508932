import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import LocationSelect from '../components/LocationSelect';

import LocationContext from '../context/LocationContext';
import AuthContext from '../context/AuthContext';

const LocationRequiredLayout = (props) => {
  const { url } = props;

  const { locationId, updateLocationId } = useContext(LocationContext);
  const { userData } = useContext(AuthContext);

  const { defaultLocation } = userData;

  useEffect(() => {
    updateLocationId(locationId || defaultLocation);
  }, [defaultLocation, updateLocationId, locationId]);

  const st = {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  };

  return locationId
    ? <Outlet />
    : (
      <div
        style={{
          alignItems: st.alignItems,
          display: st.display,
          flex: st.flex,
          flexDirection: st.flexDirection,
          justifyContent: st.justifyContent,
          width: st.width
        }}
      >
        <LocationSelect
          url={url}
          noData="No locations are linked to your account, please see an administrator."
          hideLabel={true}
          style={{ marginLeft: 0 }} />
      </div>
    );
};

export default LocationRequiredLayout;

import React, { useState } from 'react';
import moment from 'moment';

import Loader from '../../components/Loader';
import CattleDetailsNote from '../../components/CattleDetailsNote';

import HealthNoteModal from './HealthNoteModal';
import CattleGraph from './CattleGraph';

import css from './styles.module.scss';

function Details(props) {
  const {
    cattleId,
    locationId,
    updateCattleId,
    showHeader,
    areaId,
    mode = 'light',
    data,
    refetch,
    isLoading
  } = props;

  const [showHealthNoteModal, setShowHealthNoteModal] = useState(false);
  const [healthNoteType, setHealthNoteType] = useState(null);

  function addWatchListNote() {
    setShowHealthNoteModal(true);
    setHealthNoteType('watch');
  }

  function addPullListNote() {
    setShowHealthNoteModal(true);
    setHealthNoteType('pull');
  }

  function addVetListNote() {
    setShowHealthNoteModal(true);
    setHealthNoteType('vet');
  }

  const historyEls = data?.health_notes
    ? data?.health_notes
      .sort((a, b) => a.created_at < b.created_at ? 1 : -1)
      .map((note) => (
        <CattleDetailsNote key={note.id} note={note} className={css.note} />
      ))
    : 'No notes found';

  const mugImage = data?.mug_url
    ? <img src={data.mug_url} style={{ maxHeight: '200px' }} alt='' />
    : null;

  const firstSeen = data
    ? moment.utc(data?.created_at).local().format('M/D/YY hh:mm A')
    : 'N/A';
  const lastSeen = data
    ? moment.utc(data?.updated_at).local().format('M/D/YY hh:mm A')
    : 'N/A';

  return (
    <div className={css.sidebarContainer}>
      <Loader loading={isLoading}>
        <div className={`${css.content} ${css.contentCattle}`}>
          <div className={css.header}>
            <h2>ID: {data?.name || cattleId}</h2>
            <button onClick={() => updateCattleId(null)}>+</button>
          </div>

          {/*<div
            className={css.imgBlock}
            style={{ marginTop: showHeader ? 0 : 20 }}
          >
            {mugImage}
            <button
              onClick={() => console.log('live view animal click')}
              className={`btn btn-outline-primary`}
              style={{ width: '100%' }}
              disabled={true}
            >Live View Animal</button>
          </div>*/}

          <div className={css.seenBlock}>
            <div className={css.item}>
              <h4 className={css.label}>First Seen</h4>
              <span className={css.entry}>{firstSeen}</span>
            </div>
            <div className={css.item}>
              <h4 className={css.label}>Last Seen</h4>
              <span className={css.entry}>{lastSeen}</span>
            </div>
          </div>

          {/*<div
            className={css.item}
            style={{ marginTop: 10 }}
          >
            <div className={css.labelBlock}>
              <div className={css.label}>Gait Graph</div>
              <div className={css.entryAlt}>All Time</div>
            </div>
            <div
              className={css.plcBox}
              style={{ backgroundColor: mode !== 'dark' ? '#f6f6f6' : '#fff' }}
            />
          </div>*/}

          <CattleGraph
            header="Movement History"
            timeLabel="Last two weeks"
            graphLabel="Movement"
            data={[]}
          />

          <CattleGraph
            header="Head Hanging"
            timeLabel="All time"
            graphLabel="Head hanging"
            data={[]}
          />

          <div
            className={css.item}
            style={{ marginTop: 10 }}
          >
            <h3 className={css.label}>Animal History</h3>
            <div className={css.box}>
              {historyEls}
            </div>
          </div>
        </div>
        <div className={css.actions}>
          <button
            className={`btn btn-outline-primary`}
            onClick={() => addWatchListNote(cattleId)}
            disabled={isLoading}
            style={{ gap: 0, justifyContent: 'center' }}
          >
            Watch List
          </button>
          <button
            className={`btn btn-outline-primary`}
            onClick={() => addPullListNote(cattleId)}
            disabled={isLoading}
            style={{ justifyContent: 'center' }}
          >
            Pull List
          </button>
          <button
            className={`btn btn-danger ${css.danger}`}
            onClick={() => addVetListNote(cattleId)}
            disabled={isLoading}
          >
            Vet Check
          </button>
        </div>
      </Loader>

      <HealthNoteModal
        showModal={showHealthNoteModal}
        setShowModal={setShowHealthNoteModal}
        areaId={areaId}
        locationId={locationId}
        refetch={refetch}
        type={healthNoteType}
        cattleId={data?.name || cattleId}
      />
    </div>
  );
}

export default Details;

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import APIClient from '../apis/Client';

import AuthContext from '../context/AuthContext';

function clearAccessToken() {
  const cookies = new Cookies();
  cookies.remove('powershift_token', { path: '/' });
}

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const accountId = parsedUser?.id;
  const companyId = parsedUser?.company_id;

  const logout = useCallback(async () => {
    try {
      console.log('Logging out...');
      clearAccessToken();
      localStorage.removeItem('powershift_refresh');
      localStorage.removeItem('user');
      setUserData(null);
      navigate('/login', {});
    } catch (error) {
      console.error('Logout failed to remove user:', error);
    }
  }, [navigate]);

  const saveUserData = useCallback((res) => {
    const { userData: user, refreshToken } = res;
    setUserData(user);
    setIsLoading(false);

    window.localStorage.setItem("user", JSON.stringify(userData));
    if (refreshToken) {
      window.localStorage.setItem("powershift_refresh", refreshToken);
    }
    setUserData((state) => ({ ...userData }));
  }, [userData]);

  const refresh = useCallback(async (accountId, companyId, token) => {
    if (!accountId || !companyId) {
      return;
    }

    try {
      const res = await APIClient.post('/api/v1/auth/refresh', { accountId, companyId }, token);
      saveUserData(res);
    } catch (err) {
      // invalid refresh
      if (err.status === 401) {
        logout();
      } else {
        console.error(err);
      }
    }
  }, [logout, saveUserData]);

  async function runProtected(cb, ...args) {
    // refresh token if expired, then run callback
    if (userData.exp < Date.now() / 1000 - 1) {
      const token = window.localStorage.getItem('powershift_refresh');
      await refresh(accountId, companyId, token);
    }

    return cb(...args);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    let data = window.localStorage.getItem("user");
    if (!data) {
      setUserData(null);
      setIsLoading(false);
      return;
    }

    const loadData = async () => {
      data = JSON.parse(data);

      const token = window.localStorage.getItem('powershift_refresh');
      await refresh(data?.id, data?.company_id, token);

      setUserData(data);
      setIsLoading(false);
    };

    loadData();
  }, [refresh, isLoading]);

  const login = async (values) => {
    if (!values) {
      return;
    }

    try {
      const { refreshToken: token, userData: user } = values;
      localStorage.setItem('powershift_refresh', token);
      localStorage.setItem('user', JSON.stringify(user));
      setUserData(user);
    } catch (error) {
      console.error('Login failed to parse JSON:', error);
    }
  };

  function selectCompany(user) {
      localStorage.setItem('user', JSON.stringify(user));
      setUserData(user);
  }

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        userData,
        login,
        logout,
        selectCompany,
        runProtected,
        refresh
      }}
    >
      <div className="App">
        <div className="non-footer">
          <div id="PageContainer">
            {children}
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  );
};

export default AuthLayout;
